import {makeStyles} from '@mui/styles'

const useStyles = makeStyles(({layout, palette, spacing, breakpoints}) => ({
  cartSideBarWrapper: {
    position: 'fixed',
    top: spacing(12.5),
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    zIndex: 1000,
    width: '90%',
    height: '100%',
    borderTop: `1px solid ${palette.background.grayDark}`,
    boxShadow: '0px 27px 20px 20px rgba(0, 0, 0, 0.06)',
    cursor: 'default',
    background: palette.primary.main,
    color: 'white',

    [breakpoints.up('md')]: {
      width: 450,
      height: '90%',
      overflow: 'auto',
      right: '0',
      padding: spacing(5, 0),
    },

    [breakpoints.up('sm')]: {
      '& *': {
        textAlign: 'left',
      },
    },

    [breakpoints.down('sm')]: {
      position: 'fixed',
      top: '50%',
      left: 0,
      width: '100%',
      height: '100vh',
      transform: 'translateY(-50%)',
      overflow: 'scroll',
    },
  },
  subWrapper: {
    position: 'relative',
    margin: 'auto',
    maxWidth: layout.maxWidth,
    width: '90%',
    height: '100%',
    padding: `0 0 ${spacing(3.8)} 0`,
    flexDirection: 'column',
    justifyContent: 'start',
    display: 'flex',
    overflow: 'auto',

    [breakpoints.down('sm')]: {
      overflow: 'scroll',
      paddingTop: spacing(8),
    },
  },
  actions: {
    padding: spacing(2),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [breakpoints.down('sm')]: {
      paddingBottom: spacing(6),
    },
  },
}))

export default useStyles
