import * as R from 'ramda'
import {arrayOf, bool, func, shape, string} from 'prop-types'
import {Button, Card, Grid, Modal} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import React from 'react'

import Description from 'components/UI/Description'
import HashTags from 'components/UI/HashTags'
import RawText from 'components/UI/RawText'
import RoundButton from 'components/UI/RoundButton'
import Title from 'components/UI/Title'

import useStyles from './styles'

export default function SimpleModal({
  label,
  workerData,
  action,
  biographyLabel,
  educationLabel,
  workExperienceLabel,
  talentIdLabel,
  skillsLabel,
  callToActionLabel,
  isInCart,
  onTagClick,
}) {
  const [open, setOpen] = React.useState(false)
  const classes = useStyles()

  const {
    name,
    availability,
    linkedIn,
    currentPosition,
    talentId,
    role,
    yearsOfExperience,
    birth,
    birthplace,
    currentLocation,
    nativeLanguage,
    spokenLanguages,
    knowMoreLabel,
    photo,
    workExperiences,
    educations,
    skills,
    profileLong,
    knowledgeSkills,
    biography: bio,
    artificialIntelligences,
    dataGovernances,
    dataIntegrations,
    dataPlatforms,
    dataVisualizations,
    programmingLanguages,
    languages,
    otherSkills,
  } = workerData

  const allSkills = [
    artificialIntelligences,
    dataGovernances,
    dataIntegrations,
    dataPlatforms,
    dataVisualizations,
    programmingLanguages,
    otherSkills,
  ]

  const validSkillsArray = R.filter(R.is(Array), allSkills)

  const concatenedSkills = R.reduce(R.concat, [], validSkillsArray)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleAddItem = () => {
    action(workerData)
    handleClose()
  }

  const body = (
    <Grid
      item
      //   key={index}
      xs={12}
      sm={6}
      md={6}
      className={classes.cardGrid}
    >
      <Card className={classes.card}>
        <div className={classes.workerHeader}>
          <div style={{alignItems: 'center', gap: 8}}>
            <div className={classes.mainInfos}>
              <Title
                color="primary"
                variant="h3"
                type="innerTitle"
                hasMarginTop
              >
                {currentPosition}
              </Title>
              <div>
                <span className={classes.talentId}>{talentIdLabel}: </span>
                <span>{talentId}</span>
              </div>
            </div>
          </div>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}
            size="large">
            <CloseIcon />
          </IconButton>
        </div>
        <div style={{overflow: 'auto'}}>
          <div className={classes.workerInfoTop}>
            <Title variant="h4" type="innerTitle" hasMarginTop>
              Profile
            </Title>
            <RawText text={profileLong} />
          </div>
          <div className={classes.workerInfo}>
            <Title variant="h4" type="innerTitle">
              Languages
            </Title>
            {languages && (
              <HashTags
                onTagClick={onTagClick}
                tags={languages}
                hasColormapping
                useNewVersion
              />
            )}
            {/* <div>
              {R.map(
                language => (
                  <Description>{language}</Description>
                ),
                spokenLanguages,
              )}
            </div> */}
          </div>
          <div className={classes.workerInfoBottom}>
            <Title variant="h4" type="innerTitle">
              Knowledge & Skills
            </Title>
            {concatenedSkills && (
              <HashTags
                onTagClick={onTagClick}
                tags={concatenedSkills}
                hasColormapping
                useNewVersion
              />
            )}
            {/* <RawText text={knowledgeSkills} /> */}
            <div className={classes.cta}>
              <RoundButton
                isAction
                action={handleAddItem}
                isCentered
                isSmallText
                isFilledForce
                color={isInCart ? 'secondary' : 'primary'}
              >
                {isInCart ? 'Added to team' : callToActionLabel}
              </RoundButton>
            </div>
          </div>
        </div>
      </Card>
    </Grid>
  )

  return (
    <div className={classes.wrapper}>
      <Button
        className={`${classes.button} ${classes.bordered}`}
        onClick={handleOpen}
      >
        {label}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  )
}

SimpleModal.propTypes = {
  action: func.isRequired,
  label: string.isRequired,
  workerData: shape({
    name: string.isRequired,
    availability: bool.isRequired,
    linkedIn: string.isRequired,
    currentPosition: string.isRequired,
    role: string.isRequired,
    yearsOfExperience: string.isRequired,
    birth: string.isRequired,
    birthplace: string.isRequired,
    currentLocation: string.isRequired,
    nativeLanguage: string.isRequired,
    spokenLanguages: arrayOf(string).isRequired,
    knowMoreLabel: string.isRequired,
    callToActionLabel: string.isRequired,
    photo: shape({}), // Adjust the shape based on the actual structure
    biography: shape({
      biography: string.isRequired,
    }).isRequired,
  }).isRequired,
}
