import * as R from 'ramda'
import {arrayOf, func, number, shape, string} from 'prop-types'
import {Card, Grid} from '@mui/material'
import React from 'react'

import HashTags from 'components/UI/HashTags'
import RoundButton from 'components/UI/RoundButton'
import Tooltip from '@mui/material/Tooltip'
import useIsMobile from 'hooks/useIsMobile'

import useStyles from './styles'
import WorkerModal from '../WorkerModal'

const WorkerCard = ({
  index,
  workerData,
  handleAddItem,
  biographyLabel,
  educationLabel,
  workExperienceLabel,
  talentIdLabel,
  skillsLabel,
  isInCart,
  knowMoreLabel,
  callToActionLabel,
  onTagClick,
}) => {
  const classes = useStyles()
  const mobile = useIsMobile()

  const {
    currentPosition,
    talentId,
    spokenLanguages,
    languages,
    knowledgeSkills,
    artificialIntelligences,
    dataGovernances,
    dataIntegrations,
    dataPlatforms,
    dataVisualizations,
    programmingLanguages,
  } = workerData

  const profileShort = R.pathOr(
    '',
    ['profileShort', 'profileShort'],
    workerData,
  )

  const renderSpan = R.map((text, i) => <span key={i}>{text}</span>)

  const allSkills = [
    artificialIntelligences,
    dataGovernances,
    dataIntegrations,
    dataPlatforms,
    dataVisualizations,
    programmingLanguages,
  ]

  const validSkillsArray = R.filter(R.is(Array), allSkills)

  const concatenedSkills = R.reduce(R.concat, [], validSkillsArray)

  const tooltipContent =
    currentPosition && R.length(currentPosition) >= 20 ? currentPosition : ''

  function shrinkString(input, maxLength) {
    if (input.length <= maxLength) {
      return input
    }

    return `${input.substring(0, maxLength - 3)}...`
  }

  const title = currentPosition
  const maxLength = 20
  const shrunkTitle = shrinkString(title, maxLength)

  return (
    <Grid
      key={index}
      xs={12}
      sm={12}
      md={12}
      lg={6}
      className={classes.cardGrid}
    >
      <Card className={classes.card}>
        <div className={classes.workerHeader}>
          <div
          //  style={{flex: 8}}
          >
            <div className={classes.mainInfos}>
              <Tooltip title={tooltipContent}>
                <span>{shrunkTitle}</span>
              </Tooltip>

              <div className={classes.idInfos}>
                <span>{talentIdLabel}: </span>
                <span>{talentId}</span>
              </div>
            </div>
          </div>
          <WorkerModal
            label={knowMoreLabel}
            workerData={workerData}
            action={val => handleAddItem(val)}
            biographyLabel={biographyLabel}
            educationLabel={educationLabel}
            workExperienceLabel={workExperienceLabel}
            callToActionLabel={callToActionLabel}
            talentIdLabel={talentIdLabel}
            skillsLabel={skillsLabel}
            isInCart={isInCart}
            onTagClick={onTagClick}
          />
        </div>
        <div className={classes.workerInfoTop}>
          <span className={classes.label}>Profile</span>
          <p>{profileShort}</p>
        </div>
        <div className={classes.workerInfoBottom}>
          <span className={classes.label}>Languages</span>
          <div>
            {languages && (
              <HashTags
                onTagClick={onTagClick}
                tags={languages}
                hasColormapping
                useNewVersion
              />
            )}
            {/* {R.addIndex(R.map)(
              (language, index) => (
                <span>
                  {language}
                  {index !== spokenLanguages.length - 1 && ', '}
                </span>
              ),
              spokenLanguages,
            )} */}
          </div>
          <span className={classes.label}>Knowledge & Skills</span>
          <div>
            {knowledgeSkills && (
              <HashTags
                tags={concatenedSkills}
                hasColormapping
                onTagClick={onTagClick}
                useNewVersion
                maxTags={4}
              />
            )}
          </div>
        </div>

        <div className={classes.cta}>
          <RoundButton
            isAction
            action={() => handleAddItem(workerData)}
            isCentered
            isSmallText
            isFilledForce
            color={isInCart ? 'secondary' : 'primary'}
          >
            {isInCart ? 'Added to team' : callToActionLabel}
          </RoundButton>
        </div>
      </Card>
    </Grid>
  )
}

export default WorkerCard

WorkerCard.propTypes = {
  handleAddItem: func.isRequired,
  index: number.isRequired,
  workerData: shape({
    name: string.isRequired,
    availability: string.isRequired,
    linkedIn: string.isRequired,
    currentPosition: string.isRequired,
    role: string.isRequired,
    yearsOfExperience: string.isRequired,
    birth: string.isRequired,
    birthplace: string.isRequired,
    currentLocation: string.isRequired,
    nativeLanguage: string.isRequired,
    spokenLanguages: arrayOf(string).isRequired,
    knowMoreLabel: string.isRequired,
    callToActionLabel: string.isRequired,
    photo: string,
  }).isRequired,
}
