import {makeStyles} from '@mui/styles'

const useStyles = makeStyles(
  ({palette, spacing, fontSizes, breakpoints, layout}) => ({
    row: {
      display: 'flex',
      background: 'transparent',
      [breakpoints.down('sm')]: {
        width: '100%',
        flexDirection: 'column',
      },
    },
    column: {
      flex: '50%',
    },
    subTitle: {
      fontFamily: 'CamptonBook',
      fontSize: fontSizes.innerTitle,
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      margin: spacing(1.2, 0),
    },
    icon: {
      width: '50%',
      margin: 'auto',
      [breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    form: {
      fontFamily: 'CamptonBook',
      color: 'white',
      '& .hs-industry': {
        display: 'none !important',
      },
      '& .hs-input': {
        width: '100% !important',
      },
      '& .hs_submit .hs-button': {
        textIndent: 0,
        width: 'fit-content',
        cursor: 'pointer',
        borderStyle: 'outset',
        textShadow: 'none',
        color: 'white',
        outline: 'none',
        background: 'transparent',
        border: '1px solid transparent',
        backgroundColor: 'transparent',
        boxShadow: 'none',
        padding: spacing(1.8, 3.1),
        boxSizing: 'border-box',
        textTransform: 'none',
        fontFamily: 'CamptonSemiBold',
        fontSize: '1.12em',
        borderRadius: 35,
        height: 'min-content',
      },
      '& input, select, textarea': {
        height: 45,
        margin: spacing(1, 0),
        padding: 0,
        background: 'white',
        maxHeight: layout.input.maxHeight,
        outline: 'none',
        border: 'none',
        borderRadius: 4,
        width: '100%',
        fontSize: fontSizes.description,
        fontFamily: 'CamptonBook',
        textIndent: spacing(0),
        paddingLeft: spacing(1.5),
      },
      '& label': {
        fontFamily: 'CamptonBook',
      },
      '& span': {
        color: 'white',
      },
      '& textarea': {
        height: layout.input.maxHeight,
        paddingTop: spacing(1.5),
        resize: 'none',
      },
    },
    formGrid: {
      [breakpoints.down('sm')]: {
        padding: spacing(2, 0),
        height: '100%',
      },
    },
    submit: {
      cursor: 'pointer',
      borderStyle: 'outset',
      textShadow: 'none',
      color: 'white',
      outline: 'none',
      background: 'transparent',
      border: '1px solid transparent',
      backgroundColor: palette.primary.main,
      boxShadow: 'none',
      padding: spacing(1.8, 3.1),
      boxSizing: 'border-box',
      textTransform: 'none',
      fontFamily: 'CamptonSemiBold',
      fontSize: '1.12em',
      borderRadius: 35,
      height: 'min-content',
      '& > span > span': {
        lineHeight: spacing(3),
      },
      [breakpoints.down('sm')]: {
        width: 'inherit!important',
      },
    },
    gdpr: {
      margin: spacing(1, 0),
      '& *': {
        fontSize: '12px !important',
      },
    },
    privacyConsent: {
      display: 'flex',
      '& input': {
        width: 20,
        height: 20,
        justifyContent: 'start',
        margin: spacing(0, 1, 0, 0),
      },
    },
    errorClass: {
      color: 'red',
      background: 'transparent',
      listStyleType: 'none',
      margin: 0,
      padding: 0,
      fontSize: 12,
    },
    submitButtonClass: {
      display: 'none',
    },
    hubspotForm: {
      marginTop: spacing(3),
      minHeight: 269,
      height: 'fit-content',
    },
    loaderWrapper: {
      height: 269,
      display: 'flex',
    },
    // workerPillsWrapper: {
    //   display: 'flex',
    //   flexWrap: 'wrap',
    //   position: 'absolute',
    //   bottom: 16,
    //   [breakpoints.down('sm')]: {
    //     position: 'relative',
    //   },
    // },
    workerPill: {
      display: 'inline-block',
      padding: '2px 12px',
      borderRadius: 20,
      backgroundColor: 'transparent',
      color: 'white',
      fontSize: 12,
      border: '1px solid white',
      marginRight: spacing(1),
      marginTop: spacing(1),
    },
    teamSelectedLabel: {
      fontFamily: 'CamptonBook',
      fontSize: 16,
    },
  }),
)

export default useStyles
