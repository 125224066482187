import {makeStyles} from '@mui/styles'

const useStyles = makeStyles(({spacing, breakpoints, layout, palette}) => ({
  wrapper: {
    width: '100%',
    justifyContent: 'end',
    flex: 6,
  },
  main: {
    minHeight: 1000,
  },
  tagsContainer: {
    '& button': {
      marginRight: spacing(1),
    },
  },
  cardGrid: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    alignItems: 'center',
    margin: 'auto',
    display: 'flex',
    width: '100%',
    [breakpoints.down('sm')]: {
      height: '100%',
      overflow: 'scroll',
    },
  },
  card: {
    maxHeight: '90vh',
    lineHeight: spacing(4),
    border: `1px solid ${palette.background.contrastGrey}`,
    fontFamily: 'CamptonLight',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: spacing(4),
    padding: spacing(0, 5, 3, 5),
    boxShadow: 'none',
    [breakpoints.down('sm')]: {
      padding: spacing(0, 3, 3, 3),
    },
    '& div:nth-child(0)': {
      minHeight: 200,
    },
    '& img': {
      borderRadius: 4,
      objectFit: 'cover',
      height: 100,
      [breakpoints.down('sm')]: {
        marginTop: spacing(4),
        width: '100%',
      },
    },
  },
  workerHeader: {
    position: 'sticky',
    top: 0,
    background: 'white',
    display: 'flex',
    borderBottom: `1px solid ${palette.background.contrastGrey}`,
    padding: spacing(4, 0),
    justifyContent: 'space-between',
    '& > div': {
      display: 'flex',
    },
  },
  mainInfos: {
    display: 'flex',
    flexDirection: 'column',
    lineHeight: '24px',
    '& > span:first-child': {
      fontFamily: 'CamptonMedium',
    },
  },
  workerInfoTop: {
    display: 'flex',
    flexDirection: 'column',
    borderBottom: `1px solid ${palette.background.contrastGrey}`,
    padding: spacing(3, 0),
    '& h4': {
      marginBottom: spacing(1),
    },
  },
  workerInfo: {
    display: 'flex',
    flexDirection: 'column',
    borderBottom: `1px solid ${palette.background.contrastGrey}`,
    padding: spacing(3, 0),
    '& h4': {
      marginBottom: spacing(1),
    },
  },
  workerInfoBottom: {
    display: 'flex',
    flexDirection: 'column',
    padding: spacing(3, 0),
    '& h4': {
      marginBottom: spacing(1),
    },
  },
  cta: {
    display: 'flex',
    flexDirection: 'column',
    padding: spacing(1, 0),
    position: 'absolute',
    bottom: -70,
    transform: 'translate(-50%, -50%)',
    left: '50%',

    [breakpoints.down('sm')]: {
      position: 'relative',
      padding: spacing(2),
    },
  },
  icon: {
    marginTop: spacing(1),
    width: 24,
    height: 24,
  },
  slideSocials: {
    lineHeight: 'normal',
    position: 'relative',
    display: 'flex',
    '& > a': {
      opacity: '0.7',
      paddingTop: spacing(0.75),
      paddingRight: spacing(2),
      // transition: transition.animation('opacity'),
      '&:hover': {
        background: 'transparent',
        opacity: 1,
      },
    },
    '& > a:nth-last-of-type(1)': {
      paddingRight: 0,
    },
    '& img': {
      width: 24,
      height: '100%',
    },
    [breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  cardLink: {
    textDecoration: 'none',
  },
  cardMedia: {
    paddingTop: '75%',
    borderRadius: 4,
  },
  cardContent: {
    flexGrow: 1,
    width: '100%',
    padding: spacing(1, 0),
  },
  cardTitle: {
    fontWeight: 500,
    fontSize: 20,
    textAlign: 'left',
    fontFamily: 'CamptonMedium',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
  },
  image: {
    width: 50,
    height: 50,
    background: palette.background.default,
    position: 'relative',
    borderRadius: spacing(10),
    transform: 'translateZ(0)',

    '& div': {
      borderRadius: spacing(10),
      transform: 'translateZ(0)',

      width: '100%',
      height: '100%',
    },
  },
  partnersCard: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 4,
    boxShadow: 'none',
    cursor: 'pointer',
    flexWrap: 'wrap',
  },
  partnerTitle: {
    fontWeight: 500,
    fontSize: 20,
    textAlign: 'center',
    fontFamily: 'CamptonMedium',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
  },
  imageContainer: {
    display: 'flex',
    alignItems: 'center',
    width: 200,
    height: 120,
    position: 'relative',
    justifyContent: 'center',
    margin: 'auto',
    '& img': {
      objectFit: 'cover',
      maxWidth: '70%',
      maxHeight: '70%',
    },
  },

  button: {
    width: 'fit-content',
    boxShadow: 'none',
    fontSize: '1em',
    padding: `${spacing(1)} ${spacing(2.3)}`,
    boxSizing: 'border-box',
    textTransform: 'none',
    fontFamily: 'CamptonBook',
    borderRadius: 35,
    height: 'min-content',
    '& > span > span': {
      lineHeight: spacing(3),
    },
    [breakpoints.up('sm')]: {
      fontSize: '1.12em',
    },
    [breakpoints.down('sm')]: {
      width: 'inherit',
      padding: `${spacing(0.5)} ${spacing(1.15)}`,
    },
  },
  bordered: {
    fontSize: '1em',
    borderStyle: 'solid',
    background: 'transparent!important',
    borderWidth: '1px',
    color: palette.text.grey,
    borderColor: palette.text.grey,
    minWidth: 'fit-content',
    '&:hover': {
      borderColor: palette.text.grey,
      color: palette.text.light,
      backgroundColor: `${palette.text.grey} !important`,
    },
  },
  flex: {
    display: 'flex',
    gap: 10,
  },
  label: {
    fontFamily: 'CamptonMedium',
  },
  biography: {
    padding: spacing(1, 0),
    borderTop: `1px solid ${palette.background.contrastGrey}`,
    '& > span': {
      fontFamily: 'CamptonMedium',
    },
    '& > div': {
      lineHeight: 'normal',
      padding: spacing(3),
      background: palette.background.gray,
      borderRadius: spacing(2),
    },
  },
  available: {
    color: palette.primary.main,
  },
  notAvailable: {
    color: palette.tertiary.main,
  },
  experienceEducationItem: {
    display: 'flex',
    flexDirection: 'column',
    background: palette.background.greyLight,
    margin: spacing(1, 'auto'),
    padding: spacing(1),
    borderRadius: spacing(1),
    '& span:first-child': {
      fontFamily: 'CamptonSemiBold',
    },
    '& span:last-child': {
      whiteSpace: 'pre-line',
    },
  },
  skillPillContainer: {
    [breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  skillPill: {
    background: palette.background.greyLight,
    marginRight: spacing(2),
    padding: spacing(1),
    borderRadius: spacing(1),
    whiteSpace: 'pre-line',
    overflowWrap: 'break-word',
    width: '100%',
    [breakpoints.down('sm')]: {
      margin: spacing(1, 'auto'),
    },
  },
  talentId: {
    color: palette.text.grey,
    fontFamily: 'CamptonSemiBold',
  },
  closeButton: {
    position: 'absolute',
    top: spacing(2),
    right: 0,
    color: 'white',
    backgroundColor: palette.primary.main,
    cursor: 'pointer',
    '&:hover': {
      color: 'white',
      backgroundColor: palette.primary.main,
    },
    [breakpoints.down('sm')]: {
      margin: spacing(1, 'auto'),
      top: spacing(1),
    },
  },
}))

export default useStyles
