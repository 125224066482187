import {makeStyles} from '@mui/styles'

const useStyles = makeStyles(({palette, spacing}) => ({
  cartSideBarToggle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    borderRadius: spacing(4, 0, 0, 4),
    height: 50,
    width: 150,
    position: 'fixed',
    background: palette.primary.main,
    zIndex: 17,
    right: 355,
    top: 180,
    cursor: 'pointer',
  },
  cartSideBarOpen: {
    zIndex: 1001,
    right: 434,
    width: 60,
  },
  right0: {
    right: 0,
  },
  justifyContentLeft: {
    justifyContent: 'left',
  },
}))

export default useStyles
