import * as R from 'ramda'

import {getTagsObject} from 'helpers/playbook'
import {removeDuplicateObject} from 'helpers/utils'

import {tagsPrefix} from '../../keyrus/static/tagsPrefix'

export default function usePlaybookFiltersData(data) {
  const keyplays = R.pathOr(null, ['allContentfulKeyPlay', 'nodes'], data)
  const workers = R.pathOr(null, ['allContentfulWorker', 'nodes'], data)

  const keyplaysIds =
    keyplays && R.map(keyplay => keyplay.contentful_id, workers)

  const {services} = R.pathOr(null, ['contentfulCountry'], data)

  const tagsTagsObjects = getTagsObject(workers, 'tags')

  const newTagsTagsObjects = removeDuplicateObject(
    R.map(
      item => ({
        id: item.contentful_id,
        url: R.concat(tagsPrefix.tag, item.name.name),
        name: item.name.name,
      }),
      tagsTagsObjects,
    ),
  )

  const programmingLanguagesTagsObjects = getTagsObject(
    workers,
    'programmingLanguages',
  )

  const newProgrammingLanguagesTagsObjects = removeDuplicateObject(
    R.map(
      item => ({
        id: item.contentful_id,
        url: R.concat(tagsPrefix.tag, item.name.name),
        name: item.name.name,
      }),
      programmingLanguagesTagsObjects,
    ),
  )

  const dataIntegrationsTagsObjects = getTagsObject(workers, 'dataIntegrations')

  const newDataIntegrationsTagsObjects = removeDuplicateObject(
    R.map(
      item => ({
        id: item.contentful_id,
        url: R.concat(tagsPrefix.tag, item.name.name),
        name: item.name.name,
      }),
      dataIntegrationsTagsObjects,
    ),
  )

  const artificialIntelligencesTagsObjects = getTagsObject(
    workers,
    'artificialIntelligences',
  )

  const newArtificialIntelligencesTagsObjects = removeDuplicateObject(
    R.map(
      item => ({
        id: item.contentful_id,
        url: R.concat(tagsPrefix.tag, item.name.name),
        name: item.name.name,
      }),
      artificialIntelligencesTagsObjects,
    ),
  )

  const dataVisualizationsTagsObjects = getTagsObject(
    workers,
    'dataVisualizations',
  )

  const newDataVisualizationsTagsObjects = removeDuplicateObject(
    R.map(
      item => ({
        id: item.contentful_id,
        url: R.concat(tagsPrefix.tag, item.name.name),
        name: item.name.name,
      }),
      dataVisualizationsTagsObjects,
    ),
  )

  const dataGovernancesTagsObjects = getTagsObject(workers, 'dataGovernances')

  const newDataGovernancesTagsObjects = removeDuplicateObject(
    R.map(
      item => ({
        id: item.contentful_id,
        url: R.concat(tagsPrefix.tag, item.name.name),
        name: item.name.name,
      }),
      dataGovernancesTagsObjects,
    ),
  )

  const dataPlatformsTagsObjects = getTagsObject(workers, 'dataPlatforms')

  const newDataPlatformsTagsObjects = removeDuplicateObject(
    R.map(
      item => ({
        id: item.contentful_id,
        url: R.concat(tagsPrefix.tag, item.name.name),
        name: item.name.name,
      }),
      dataPlatformsTagsObjects,
    ),
  )

  const languagesTagsObjects = getTagsObject(workers, 'languages')

  const newLanguagesTagsObjects = removeDuplicateObject(
    R.map(
      item => ({
        id: item.contentful_id,
        url: R.concat(tagsPrefix.tag, item.name.name),
        name: item.name.name,
      }),
      languagesTagsObjects,
    ),
  )

  const skillsTagsObjects = getTagsObject(workers, 'otherSkills')

  const newSkillsTagsObjects = removeDuplicateObject(
    R.map(
      item => ({
        id: item.contentful_id,
        url: R.concat(tagsPrefix.tag, item.name.name),
        name: item.name.name,
      }),
      skillsTagsObjects,
    ),
  )

  const removeDuplicates = R.uniqBy(R.prop('id'))

  const allTagsObjects = removeDuplicates(
    R.pipe(
      R.concat(newTagsTagsObjects),
      R.concat(newProgrammingLanguagesTagsObjects),
      R.concat(newDataIntegrationsTagsObjects),
      R.concat(newArtificialIntelligencesTagsObjects),
      R.concat(newDataVisualizationsTagsObjects),
      R.concat(newDataGovernancesTagsObjects),
      R.concat(newDataPlatformsTagsObjects),
      R.concat(newLanguagesTagsObjects),
      R.concat(newSkillsTagsObjects),
    )([]),
  )

  return {
    allTagsObjects,
    newTagsTagsObjects,
    keyplaysIds,
    services,
    newProgrammingLanguagesTagsObjects,
    newDataIntegrationsTagsObjects,
    newArtificialIntelligencesTagsObjects,
    newDataVisualizationsTagsObjects,
    newDataGovernancesTagsObjects,
    newDataPlatformsTagsObjects,
    newLanguagesTagsObjects,
    newSkillsTagsObjects,
  }
}
