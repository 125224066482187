import Avatar from '@mui/material/Avatar'
import React from 'react'

import {mediaPropTypes} from 'helpers/propTypes'
import Media from 'components/UI/Media'

import useStyles from './styles'

const AvatarComponent = ({photo}) => {
  const classes = useStyles()

  return (
    <Avatar className={classes.avatar}>
      <Media className={classes.image} data={photo.gatsbyImageData} />
    </Avatar>
  )
}

export default AvatarComponent

AvatarComponent.propTypes = {
  photo: mediaPropTypes,
}
AvatarComponent.defaultProps = {
  photo: {},
}
