/* eslint-disable no-restricted-globals */
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import classNames from 'classnames'
import PersonIcon from '@mui/icons-material/Person'
import React from 'react'
import Slide from '@mui/material/Slide'

import {SwitchLanguagePropTypes} from 'helpers/propTypes'
import CircleBadge from 'components/UI/CircleBadge'

import useStyles from './styles'

const CartSideBarToggle = ({
  handleShowCartSideBarToggle,
  showCartSideBarToggle,
  itemsLength,
  isDisplayed,
}) => {
  const classes = useStyles()

  const handleClick = () => {
    handleShowCartSideBarToggle()
  }

  const iconStyle = {color: 'white', width: 40}

  const toggleClassNames = classNames(classes.cartSideBarToggle, {
    [classes.cartSideBarOpen]: showCartSideBarToggle,
    [classes.right0]: !showCartSideBarToggle,
    [classes.justifyContentLeft]: showCartSideBarToggle,
  })

  return isDisplayed ? (
    <div>
      <Slide direction="left" in>
        <div
          className={toggleClassNames}
          onClick={handleClick}
          onKeyDown={e => {
            if (e.key === 'Enter' || e.key === ' ') {
              handleClick()
            }
          }}
          role="button"
          tabIndex={0}
        >
          {!showCartSideBarToggle ? (
            <>
              <ChevronLeftIcon style={iconStyle} />
              <PersonIcon style={iconStyle} />
              <CircleBadge jobRecordCount={itemsLength} position="initial" />
            </>
          ) : (
            <ChevronRightIcon style={iconStyle} />
          )}
        </div>
      </Slide>
    </div>
  ) : null
}

CartSideBarToggle.propTypes = SwitchLanguagePropTypes

CartSideBarToggle.defaultProps = {}

export default CartSideBarToggle
