import {makeStyles} from '@mui/styles'

const useStyles = makeStyles(({spacing, palette}) => ({
  avatar: {
    display: 'flex',
    width: spacing(7),
    height: spacing(7),
    backgroundColor: palette.background.gray,
  },

  image: {
    scale: 1.6,
  },
}))

export default useStyles
