import * as R from 'ramda'
import React, {createRef, useEffect, useState} from 'react'

import {SwitchLanguagePropTypes} from 'helpers/propTypes'
import useIsMobile from 'hooks/useIsMobile'

import CartSideBarToggle from './CartSideBarToggle'
import SwitchActions from './SwitchActions'
import SwitchComponent from './SwitchComponent'
import useStyles from './styles'

const CartSideBar = ({items, setItems, formRedirect, formData, location}) => {
  const rootRef = createRef()
  const classes = useStyles()
  const isMobile = useIsMobile()

  const [showCartSideBarToggle, setShowCartSideBarToggle] = useState(false)
  const [itemLength, setItemLength] = useState(R.length(items))
  const [isThanksActive, setIsThanksActive] = useState(false)
  const [component, setComponent] = useState('')
  const [formSubmitted, setFormSubmitted] = useState(false)
  const hasItemInCart = itemLength !== 0

  const handleComponent = val => {
    setComponent(val)
  }

  const handleFormSubmission = e => {
    e.preventDefault()

    return document.querySelector('.hs-submit input').click()
  }

  const handlePostFormSubmission = () => {
    localStorage.clear()
    setItems([])
    setIsThanksActive(true)
  }

  const handleShowCartSideBarToggle = () => {
    setShowCartSideBarToggle(prevState => !prevState)
  }

  useEffect(() => {
    setItemLength(R.length(items))
  }, [items])

  useEffect(() => {
    const handleToggle = () => {
      if (!hasItemInCart) {
        setShowCartSideBarToggle(false)
      }
    }

    handleToggle()
  }, [itemLength])

  useEffect(() => {
    const handleKeyDown = event => {
      if (event.key === 'Escape') {
        setShowCartSideBarToggle(false)
      }
    }

    if (showCartSideBarToggle) {
      window.addEventListener('keydown', handleKeyDown)
    } else {
      window.removeEventListener('keydown', handleKeyDown)
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [showCartSideBarToggle])

  useEffect(() => {
    if (formSubmitted) {
      handlePostFormSubmission()
    }
  }, [formSubmitted])

  useEffect(() => {
    // Disable/enable body scrolling based on popup visibility
    if (showCartSideBarToggle || isThanksActive) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [showCartSideBarToggle, isThanksActive])

  return (
    <div tabIndex={0}>
      <CartSideBarToggle
        handleShowCartSideBarToggle={handleShowCartSideBarToggle}
        showCartSideBarToggle={showCartSideBarToggle}
        itemsLength={itemLength}
        isDisplayed={
          ((hasItemInCart || isThanksActive) &&
            (!isMobile || !showCartSideBarToggle)) ||
          formSubmitted
        }
      />

      {(showCartSideBarToggle || isThanksActive) && (
        <div>
          <div ref={rootRef} className={classes.cartSideBarWrapper}>
            <div className={classes.subWrapper}>
              <SwitchComponent
                component={component}
                handleComponent={handleComponent}
                items={items}
                setItems={setItems}
                setFormSubmitted={setFormSubmitted}
                formRedirect={formRedirect}
                formData={formData}
                location={location}
              />
            </div>
            {!formSubmitted && (
              <div className={classes.actions}>
                <SwitchActions
                  component={component}
                  handleComponent={handleComponent}
                  setShowCartSideBarToggle={setShowCartSideBarToggle}
                  handleFormSubmission={handleFormSubmission}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

CartSideBar.propTypes = SwitchLanguagePropTypes

CartSideBar.defaultProps = {
  continents: null,
  currentCountry: '',
  currentNodeLocale: '',
  setIsModalLangOpen: null,
}

export default CartSideBar
