import {useMemo} from 'react'

const useFilteredWorkers = (workers, selectedTags) =>
  useMemo(
    () =>
      workers.filter(
        worker =>
          selectedTags.length === 0 ||
          selectedTags.every(
            tag =>
              worker.dataIntegrations?.some(
                t => `tag-${t.name.name}` === tag,
              ) ||
              worker.artificialIntelligences?.some(
                t => `tag-${t.name.name}` === tag,
              ) ||
              worker.programmingLanguages?.some(
                t => `tag-${t.name.name}` === tag,
              ) ||
              worker.dataVisualizations?.some(
                t => `tag-${t.name.name}` === tag,
              ) ||
              worker.dataGovernances?.some(t => `tag-${t.name.name}` === tag) ||
              worker.dataPlatforms?.some(t => `tag-${t.name.name}` === tag) ||
              worker.languages?.some(t => `tag-${t.name.name}` === tag) ||
              worker.otherSkills?.some(t => `tag-${t.name.name}` === tag),
          ),
      ),
    [workers, selectedTags],
  )

export default useFilteredWorkers
