/* eslint-disable react/no-danger */
/* eslint-disable no-undef */
import * as R from 'ramda'
import {bool, shape, string} from 'prop-types'
import React, {useEffect, useState} from 'react'

import {addExternalScript} from 'helpers/utils'
import {locationPropTypes, rawTextPropTypes} from 'helpers/propTypes'
import Loader from 'components/UI/Loader'
import RawText from 'components/UI/RawText'
import RoundButton from 'components/UI/RoundButton'
import Section from 'components/UI/Section'
import Title from 'components/UI/Title'

import useStyles from './styles'

const CartSideBarForm = ({
  data,
  hubSpotCountry,
  locale,
  redirection,
  isContact,
  location,
  isArticle,
  titleVariant,
  hasNoTitle,
  isPopup,
  formId,
  items,
  setFormSubmitted,
  formTitle,
}) => {
  const classes = useStyles()
  const mapIndexed = R.addIndex(R.map)
  const {pathname} = location
  const [checkboxState, setCheckboxState] = useState(false)
  const [formIsLoaded, setFormIsLoaded] = useState(false)
  const defaultFormId = '0300a488-e97c-46c0-9189-26bf836a873f'

  const workersNames = R.pipe(R.map(R.prop('talentId')), R.join(', '))(items)

  const {
    title,
    description,
    firstnamePlaceholder,
    lastnamePlaceholder,
    emailPlaceholder,
    phonePlaceholder,
    companyPlaceholder,
    messagePlaceholder,
    callToAction,
    icon,
    termsOfUseConsent,
    privacyPolicyConsent,
  } = data

  const formLocale = locale === 'pt' ? 'pt-br' : locale

  const initEmbeddedForm = async () => {
    if (hbspt && data) {
      hbspt.forms.create({
        region: 'eu1',
        portalId: '25509106',
        formId: formId || defaultFormId,
        target: '#workersForm',
        redirectUrl: redirection,
        locale,
        cssClass: classes.form,
        errorMessageClass: classes.errorClass,
        submitButtonClass: classes.submitButtonClass,
        // translations: {
        //   [`${formLocale}`]: {
        //     fieldLabels: {
        //       firstname: firstnamePlaceholder,
        //       lastname: lastnamePlaceholder,
        //       email: emailPlaceholder,
        //       phone: phonePlaceholder,
        //       company: companyPlaceholder,
        //       // eslint-disable-next-line camelcase
        //       description__c: messagePlaceholder,
        //     },
        //     submitText: callToAction,
        //   },
        // },
      })
    }

    return setTimeout(() => {
      setFormIsLoaded(true)
    }, 1000)
  }

  useEffect(() => {
    addExternalScript('https://js-eu1.hsforms.net/forms/v2.js', () =>
      initEmbeddedForm(),
    )
  }, [])

  useEffect(() => {
    window.addEventListener('message', event => {
      if (
        event.data.type === 'hsFormCallback' &&
        event.data.eventName === 'onFormReady'
      ) {
        const lcountry = document.querySelector(
          '#workersForm input[name="leadcountry__c"]',
        )
        const lastLandingPage = document.querySelector(
          '#workersForm input[name="last_landing_page"]',
        )
        const workersSelected = document.querySelector(
          '#workersForm input[name="workers_selected"]',
        )

        lastLandingPage.value = pathname
        lcountry.value = hubSpotCountry
        workersSelected.value = workersNames
      }
    })
  }, [formIsLoaded])

  const handleCheckbox = () => {
    setCheckboxState(prevstate => !prevstate)
  }

  // const handleFormSubmission = e => {
  //   e.preventDefault()

  //   if (!checkboxState) {
  //     return false
  //   }

  //   if (isPopup) {
  //     return document.querySelectorAll('.hs-submit input')[1].click()
  //   }

  //   return document.querySelector('.hs-submit input').click()
  // }

  const handleFormDisplay = () =>
    formIsLoaded ? {display: 'block'} : {display: 'none'}

  useEffect(() => {
    window.addEventListener('message', event => {
      if (
        event.data.type === 'hsFormCallback' &&
        event.data.eventName === 'onFormSubmit'
      ) {
        setFormSubmitted(true)
      }
    })
  }, [formIsLoaded])

  return (
    <>
      {data && (
        // <Section hasPaddingTop={false} hasPaddingBottom={false}>
        <div className={classes.row}>
          <div className={classes.column}>
            <div className={classes.formGrid} style={{padding: isContact && 0}}>
              {!hasNoTitle && (
                <>
                  <Title
                    hasSmallPaddingBottom
                    color="white"
                    variant={titleVariant}
                    type="subTitle"
                  >
                    {formTitle}
                  </Title>
                </>
              )}
              <div
                id="workersForm"
                className={classes.hubspotForm}
                style={handleFormDisplay()}
              />
              {formIsLoaded && (
                <div
                // className={classes.workerPillsWrapper}
                >
                  <span className={classes.teamSelectedLabel}>
                    Team selected
                  </span>
                  <div>
                    {mapIndexed(
                      (item, index) => (
                        <div key={index} className={classes.workerPill}>
                          {item?.talentId}
                        </div>
                      ),
                      items,
                    )}
                  </div>
                </div>
              )}
              {!formIsLoaded && (
                <div className={classes.loaderWrapper}>
                  <Loader isWhite />
                </div>
              )}
              {/* <div className={classes.gdpr}>
                <div className={classes.privacyConsent}>
                  <input
                    onClick={handleCheckbox}
                    checked={checkboxState}
                    type="checkbox"
                    required
                  />
                  <RawText isArticle={isArticle} text={privacyPolicyConsent} />
                </div>
                <RawText isArticle={isArticle} text={termsOfUseConsent} />
              </div> */}
              {/* <RoundButton action={handleFormSubmission}>
                {callToAction}
              </RoundButton> */}
            </div>
          </div>
        </div>
        // </Section>
      )}
    </>
  )
}

export default CartSideBarForm

CartSideBarForm.propTypes = {
  data: shape({
    title: string,
    description: string,
    firstnamePlaceholder: string,
    lastnamePlaceholder: string,
    emailPlaceholder: string,
    phonePlaceholder: string,
    companyPlaceholder: string,
    messagePlaceholder: string,
    callToAction: string,
    icon: shape({
      file: shape({url: string, fileName: string, contentType: string}),
    }),
    termsOfUseConsent: rawTextPropTypes,
    privacyPolicyConsent: rawTextPropTypes,
  }),
  hasNoTitle: bool,
  hubSpotCountry: string,
  isArticle: bool,
  isContact: bool,
  isPopup: bool,
  locale: string,
  location: locationPropTypes,
  redirection: string,
  titleVariant: string,
}

CartSideBarForm.defaultProps = {
  data: shape({
    title: '',
    description: '',
    firstnamePlaceholder: '',
    lastnamePlaceholder: '',
    emailPlaceholder: '',
    phonePlaceholder: '',
    companyPlaceholder: '',
    messagePlaceholder: '',
    callToAction: '',
    icon: shape({
      file: shape({url: '', fileName: '', contentType: ''}),
    }),
    termsOfUseConsent: {},
    privacyPolicyConsent: {},
  }),
  hasNoTitle: false,
  hubSpotCountry: '',
  isArticle: false,
  isContact: false,
  isPopup: false,
  locale: '',
  location: {},
  redirection: '',
  titleVariant: 'h2',
}
