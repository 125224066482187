import * as R from 'ramda'
import {Container, Grid, Typography} from '@mui/material'
import {createHash, createKlsHash, scrollTop} from 'helpers/utils'
import {keyplaysPropTypes} from 'helpers/propTypes'
import {navigate} from 'gatsby'
import InfiniteScrollObserver from 'components/UI/InfiniteScrollObserver'
import LoadMore from 'components/UI/LoadMore'
import React, {useEffect, useRef, useState} from 'react'
import Tag from 'components/PlayBook/Tag'
import useFilter from 'hooks/useTalentsFilter'
import useIsBrowser from 'hooks/useIsBrowser'
import useIsMobile from 'hooks/useIsMobile'
import useObserver from 'hooks/useObserver'
import useStyles from './styles'
import WorkerCard from './WorkerCard'

const Workers = ({
  currentTags,
  filterSeeMoreLabel,
  workers,
  instaIcon,
  items,
  setItems,
  biographyLabel,
  educationLabel,
  workExperienceLabel,
  talentIdLabel,
  skillsLabel,
  knowMoreLabel,
  callToActionLabel,
  setSelectedTags,
  onTagClick,
}) => {
  const classes = useStyles()
  const loadRef = useRef()
  const mobile = useIsMobile()
  const mapIndexed = R.addIndex(R.map)
  const [limit, setLimit] = useState(10)
  const [loadMore, setLoadMore] = useState(false)
  const [itemsInCart, setItemsInCart] = useState({})
  const hasSelectedTags = currentTags.length > 0

  const itemsToRender = workers

  const loadMoreItems = () => {
    if (limit <= R.length(itemsToRender)) {
      setLoadMore(true)
      setTimeout(() => {
        setLimit(val => val + 12)
        setLoadMore(false)
      }, 1000)
    }
  }

  const [infiniteScroll, setInfiniteScroll] = useObserver(
    loadRef,
    loadMoreItems,
  )

  const handleLoadMore = () => {
    setInfiniteScroll(true)
    loadMoreItems()
  }

  useEffect(() => {
    setLimit(12)
    if (useIsBrowser) {
      if (hasSelectedTags) {
        setInfiniteScroll(false)
      }
    }
  }, [currentTags])

  const handleAddItem = item => {
    const {contentful_id: currentItemId} = item
    const existingItems = JSON.parse(localStorage.getItem('items')) || []
    const itemExists = existingItems.some(
      existingItem => existingItem.contentful_id === currentItemId,
    )

    if (!itemExists) {
      existingItems.push(item)
      localStorage.setItem('items', JSON.stringify(existingItems))
      setItems(prevItems => [...prevItems, item])
    }
  }

  useEffect(() => {
    const itemsMap = items.reduce((acc, item) => {
      acc[item.contentful_id] = true

      return acc
    }, {})

    setItemsInCart(itemsMap)
  }, [items])

  const handleTagClick = tag => {
    const newSelectedTags = currentTags.filter(t => t.url !== tag)
    const newQueryString =
      newSelectedTags.length > 0
        ? `?tags=${newSelectedTags.map(t => t.url).join(',')}`
        : ''

    navigate(`${window.location.pathname}${newQueryString}`)
  }

  const WorkerCards = () =>
    mapIndexed(
      (coreValue, index) => (
        <WorkerCard
          key={index}
          index={index}
          workerData={coreValue}
          icon={instaIcon}
          handleAddItem={handleAddItem}
          biographyLabel={biographyLabel}
          educationLabel={educationLabel}
          workExperienceLabel={workExperienceLabel}
          talentIdLabel={talentIdLabel}
          skillsLabel={skillsLabel}
          isInCart={itemsInCart[coreValue.contentful_id] || false}
          knowMoreLabel={knowMoreLabel}
          callToActionLabel={callToActionLabel}
          onTagClick={onTagClick}
        />
      ),
      itemsToRender,
    )

  return (
    <main className={classes.main}>
      <div className={classes.tagsContainer}>
        {R.map(tag => {
          const [type] = tag.url.split('-')

          return (
            <Tag
              useNewVersion
              key={tag.url}
              text={tag.name}
              type={type}
              onTagClick={handleTagClick}
            />
          )
        }, currentTags)}
      </div>
      <Container className={classes.cardGrid} maxWidth="lg">
        {itemsToRender.length === 0 ? (
          <Typography variant="h6" align="center" style={{margin: '20px 0'}}>
            No workers found.
          </Typography>
        ) : (
          <Grid
            container
            spacing={4}
            style={{margin: 0, width: mobile && '100%'}}
          >
            <WorkerCards />
          </Grid>
        )}
      </Container>
      {/* {hasMoreItems && (
        <LoadMore
          label={filterSeeMoreLabel}
          loadMore={loadMore}
          hasMoreItems={hasMoreItems}
          infiniteScroll={infiniteScroll}
          handleLoadMore={handleLoadMore}
          hasPaddingTop
        />
      )}
      <InfiniteScrollObserver show={infiniteScroll} Ref={loadRef} /> */}
    </main>
  )
}

Workers.propTypes = keyplaysPropTypes

Workers.defaultProps = {}

export default Workers
