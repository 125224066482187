/* eslint-disable camelcase */
import * as R from 'ramda'
import {arrayOf, bool, func, shape, string} from 'prop-types'
import React from 'react'

import Title from 'components/UI/Title'
import useIsMobile from 'hooks/useIsMobile'

import CartItem from '../CartItem'
import CartSideBarForm from '../CartSideBarForm'

const SwitchComponent = ({
  component,
  items,
  setItems,
  setFormSubmitted,
  formRedirect,
  formData,
  location,
}) => {
  const mapIndexed = R.addIndex(R.map)
  const isMobile = useIsMobile()

  const CartItemsList = () => (
    <div
      style={{
        paddingTop: isMobile && 16,
      }}
    >
      <div>
        <Title color="white" variant="h2" type="subTitle">
          Talents I´m interested in:
        </Title>
      </div>
      {mapIndexed(
        (item, index) => (
          <CartItem
            key={index}
            index={index}
            itemData={item}
            item={item}
            items={items}
            setItems={setItems}
          />
        ),
        items,
      )}
    </div>
  )

  switch (component) {
    case '':
      return <CartItemsList />
    case 'form':
      return (
        <CartSideBarForm
          formTitle="Tell us a bit more about you"
          titleVariant="h3"
          items={items}
          setFormSubmitted={setFormSubmitted}
          redirection={formRedirect}
          data={formData}
          location={location}
        />
      )
    default:
      return <CartItemsList />
  }
}

export default SwitchComponent

SwitchComponent.propTypes = {
  component: string.isRequired,
  formRedirect: string.isRequired,
  items: arrayOf(
    shape({
      availability: bool.isRequired,
      biography: shape({
        biography: string.isRequired,
      }).isRequired,
      birth: string.isRequired,
      birthplace: string.isRequired,
      callToActionLabel: string.isRequired,
      contentful_id: string.isRequired,
      currentLocation: string.isRequired,
      currentPosition: string.isRequired,
      knowMoreLabel: string.isRequired,
      linkedIn: string.isRequired,
      name: string.isRequired,
      nativeLanguage: string.isRequired,
      photo: shape({
        gatsbyImageData: shape({}),
        file: shape({}),
      }).isRequired,
      role: string.isRequired,
      spokenLanguages: arrayOf(string).isRequired,
      yearsOfExperience: string.isRequired,
    }),
  ).isRequired,
  setFormSubmitted: func.isRequired,
  setItems: func.isRequired,
}
