import {makeStyles} from '@mui/styles'

const useStyles = makeStyles(({breakpoints, palette, spacing}) => ({
  actionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: spacing(1),
    '& button': {
      backgroundColor: 'white!important',
      '& span': {
        color: palette.primary.main,
      },
      [breakpoints.up('sm')]: {
        padding: `${spacing(2)} ${spacing(14)}`,
      },
    },
  },
  backLink: {
    padding: spacing(2),
    color: 'white',
    textDecoration: 'underline',
    '& svg': {
      fill: 'white',
    },
  },
}))

export default useStyles
