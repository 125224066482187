const useTagClick = (location, selectedTags, setSelectedTags) => {
  const handleTagClick = tag => {
    let newSelectedTags

    if (selectedTags.includes(tag)) {
      newSelectedTags = selectedTags.filter(t => t !== tag)
    } else {
      newSelectedTags = [...selectedTags, tag]
    }
    setSelectedTags(newSelectedTags)
    const newQueryString =
      newSelectedTags.length > 0
        ? `?tags=${newSelectedTags.map(encodeURIComponent).join(',')}`
        : ''
    const newUrl = `${location.pathname}${newQueryString}`

    window.history.pushState({}, '', newUrl)
  }

  return handleTagClick
}

export default useTagClick
