import {makeStyles} from '@mui/styles'

const useStyles = makeStyles(({breakpoints, palette, spacing, fontSizes}) => ({
  cartItemWrapper: {
    padding: spacing(7, 2),
    margin: `${spacing(2)} 0`,
    display: 'flex',
    position: 'relative',
    height: spacing(11),
    background: 'white',
    borderRadius: spacing(2),
    color: 'black',
  },
  cartItemContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    justifyContent: 'space-between',
  },
  avatarContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: spacing(2),
  },
  avatarInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    '& span:nth-child(1)': {
      fontSize: fontSizes.description,
    },
    '& div': {
      display: 'flex',
      flexDirection: 'row',
    },
    '& div span:nth-child(1)': {
      display: 'flex',
      color: palette.text.grey,
      fontFamily: 'CamptonSemiBold',
      marginRight: spacing(1),
      fontSize: fontSizes.tagButton,
    },
    '& div span:nth-child(2)': {
      fontSize: fontSizes.tagButton,
    },
    '& > span:last-child': {
      fontFamily: 'CamptonLight',
    },
  },
  removeButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
  },
  iconButtonWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  iconButton: {
    backgroundColor: 'red!important',
    color: 'white!important',
    '& svg': {
      fill: 'white',
      height: spacing(2),
      width: spacing(2),
    },
  },
  remove: {
    color: palette.tertiary.main,
    fontFamily: 'CamptonLight',
    fontSize: fontSizes.tagButton,
  },
}))

export default useStyles
