/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import * as R from 'ramda'
import {arrayOf, bool, func, number, shape, string} from 'prop-types'
import {Container, IconButton} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import React from 'react'

import Avatar from '../../Avatar'
import useStyles from './styles'

const CartItem = ({index, itemData, item, setItems}) => {
  const classes = useStyles()
  const {availability, currentPosition, talentId} = R.pathOr('', [], itemData)

  const handleRemoveItem = clickedItem => {
    const currentItemId = R.path(['contentful_id'], clickedItem)
    const existingItems = JSON.parse(localStorage.getItem('items')) || []
    const updatedItems = R.reject(
      R.propEq('contentful_id', currentItemId),
      existingItems,
    )

    localStorage.setItem('items', JSON.stringify(updatedItems))
    setItems(updatedItems)
  }

  return (
    <div className={classes.cartItemWrapper}>
      <Container component="main" maxWidth="xs">
        <div className={classes.cartItemContainer}>
          <div className={classes.avatarContainer}>
            {/* <Avatar photo={item.photo} /> */}
            <div className={classes.avatarInfoContainer}>
              <span>{currentPosition}</span>
              <div>
                <span>TalentId label: </span>
                <span>{talentId}</span>
              </div>
              {/* {availability ? (
                <span className={classes.available}>Available</span>
              ) : (
                <span className={classes.notAvailable}>Not available</span>
              )} */}
            </div>
          </div>
          <div className={classes.removeButtonContainer}>
            <div
              role="button"
              tabIndex={index}
              className={classes.iconButtonWrapper}
              onClick={() => handleRemoveItem(item)}
              onKeyDown={e => {
                if (e.key === 'Enter' || e.key === ' ') {
                  handleRemoveItem(item)
                }
              }}
            >
              <IconButton className={classes.iconButton} size="large">
                <CloseIcon />
              </IconButton>
              <span className={classes.remove}>remove</span>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}

CartItem.propTypes = {
  index: number.isRequired,
  item: shape({
    availability: bool.isRequired,
    biography: shape({
      biography: string.isRequired,
    }).isRequired,
    birth: string.isRequired,
    birthplace: string.isRequired,
    callToActionLabel: string.isRequired,
    contentful_id: string.isRequired,
    currentLocation: string.isRequired,
    currentPosition: string.isRequired,
    knowMoreLabel: string.isRequired,
    linkedIn: string.isRequired,
    name: string.isRequired,
    nativeLanguage: string.isRequired,
    photo: shape({
      file: shape({}),
      gatsbyImageData: shape({}),
    }).isRequired,
    role: string.isRequired,
    spokenLanguages: arrayOf(string).isRequired,
    yearsOfExperience: string.isRequired,
  }).isRequired,
  itemData: shape({
    availability: bool.isRequired,
    biography: shape({
      biography: string.isRequired,
    }).isRequired,
    birth: string.isRequired,
    birthplace: string.isRequired,
    callToActionLabel: string.isRequired,
    contentful_id: string.isRequired,
    currentLocation: string.isRequired,
    currentPosition: string.isRequired,
    knowMoreLabel: string.isRequired,
    linkedIn: string.isRequired,
    name: string.isRequired,
    nativeLanguage: string.isRequired,
    photo: shape({
      file: shape({}),
      gatsbyImageData: shape({}),
    }).isRequired,
    role: string.isRequired,
    spokenLanguages: arrayOf(string).isRequired,
    yearsOfExperience: string.isRequired,
  }).isRequired,
  setItems: func.isRequired,
}

export default CartItem
