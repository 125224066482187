import {func, string} from 'prop-types'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import React from 'react'

import RoundButton from 'components/UI/RoundButton'

import useStyles from './styles'

const SwitchActions = ({
  component,
  handleComponent,
  setShowCartSideBarToggle,
  handleFormSubmission,
}) => {
  const classes = useStyles()

  const backLinkStyle = {
    padding: '8px',
    color: 'white',
    textDecoration: 'underline',
  }

  const handleSubmit = e => {
    handleFormSubmission(e)
  }

  switch (component) {
    case '':
      return (
        <div className={classes.actionsContainer}>
          <RoundButton
            isAction
            action={() => handleComponent('form')}
            isReversed
          >
            Request information
          </RoundButton>
          <div
            role="button"
            tabIndex={0}
            onClick={() => {
              setShowCartSideBarToggle(prevState => !prevState)
            }}
            onKeyDown={e => {
              if (e.key === 'Enter' || e.key === ' ') {
                setShowCartSideBarToggle(prevState => !prevState)
              }
            }}
            style={backLinkStyle}
          >
            Back to team selection
          </div>
        </div>
      )
    case 'form':
      return (
        <div className={classes.actionsContainer}>
          <RoundButton isAction action={e => handleSubmit(e)} isReversed>
            Request information
          </RoundButton>
          <div
            role="button"
            tabIndex={0}
            onClick={() => handleComponent('')}
            className={classes.backLink}
            onKeyDown={e => {
              if (e.key === 'Enter' || e.key === ' ') {
                handleComponent('')
              }
            }}
            style={backLinkStyle}
          >
            <KeyboardArrowLeftIcon style={{fill: 'white'}} />
            Back to team List
          </div>
        </div>
      )
    default:
      return null
  }
}

export default SwitchActions

const SwitchActionsPropTypes = {
  component: string.isRequired,
  handleComponent: func.isRequired,
  handleFormSubmission: func.isRequired,
  setShowCartSideBarToggle: func.isRequired,
}

SwitchActions.propTypes = SwitchActionsPropTypes
