import * as R from 'ramda'
import {arrayOf, shape} from 'prop-types'
import {graphql} from 'gatsby'
import {Grid} from '@mui/material'
import loadable from '@loadable/component'
import queryString from 'query-string'
import React, {useEffect, useState} from 'react'

import useFilteredWorkers from 'hooks/useFilteredWorkers'
import useScrollIntoView from 'hooks/useScrollIntoView'
import useTagClick from 'hooks/useTagClick'

import {
  FilterPropTypes,
  FiltersContainerPropTypes,
  locationPropTypes,
  pageContextPropTypes,
  playbookHeaderPropTypes,
} from 'helpers/propTypes'
import {formRedirection} from 'helpers/utils'

import CartSideBar from 'components/Talents/CartSideBar'
import Filter from 'components/UI/Filter'
import FiltersContainer from 'components/UI/FiltersContainer'
import Section from 'components/UI/Section'
import SectionComponent from 'components/UI/SectionComponent'
import SEO from 'components/seo'
import useIsMobile from 'hooks/useIsMobile'
import useTagsObject from 'hooks/useTagsObject'
import useTalentsFiltersData from 'hooks/useTalentsFiltersData'
import Workers from 'components/Talents/Workers'

import Modal from 'components/UI/MediaComponent/Modal'

const Talents = ({pageContext, data, location}) => {
  const countryData = R.path(['contentfulCountry'], data)
  const Decoration = loadable(() => import('components/UI/Decoration'))
  const Decorations = loadable(() => import('components/UI/Decorations'))

  const {hash} = location

  const formData = R.pathOr(null, ['contentfulForm'], data)

  const {navLocale, countryCode} = countryData

  useScrollIntoView(hash)

  const [items, setItems] = useState(() => {
    if (typeof window !== 'undefined') {
      const storedData = localStorage.items

      return storedData ? JSON.parse(storedData) : []
    }
  })

  const {
    metaTitle,
    metaDescription,
    sections,
    stepByStepSectionContent,
    modalContent,
    modalContentItems,
    searchPlaceholder,

    filterByDataIntegrationLabel,
    filterByArtificialIntelligenceLabel,
    filterByProgrammingLanguageLabel,
    filterByDataVisualizationLabel,
    filterByDataGovernanceLabel,
    filterByDataPlatformsLabel,
    filterByOtherSkillsLabel,

    filterSeeMoreLabel,
    filterSeeLessLabel,
    biographyLabel,
    workExperienceLabel,
    talentIdLabel,
    skillsLabel,
    knowMoreLabel,
    callToActionLabel,
  } = R.pathOr(null, ['allContentfulTalents', 'edges', 0, 'node'], data)

  const instaIcon = R.pathOr(
    null,
    ['contentfulSocialMediaShare', 'icon', 'file', 'url'],
    data,
  )

  const workers = R.pathOr(null, ['allContentfulWorker', 'nodes'], data)

  const {
    allTagsObjects,
    newProgrammingLanguagesTagsObjects,
    newDataIntegrationsTagsObjects,
    newArtificialIntelligencesTagsObjects,
    newDataVisualizationsTagsObjects,
    newDataGovernancesTagsObjects,
    newDataPlatformsTagsObjects,
    newLanguagesTagsObjects,
    newSkillsTagsObjects,
  } = useTalentsFiltersData(data)

  const isMobile = useIsMobile()
  const query = queryString.parse(location.search)
  const initialTags = query.tags
    ? query.tags.split(',').map(decodeURIComponent)
    : []

  const [selectedTags, setSelectedTags] = useState(initialTags)
  const [selectedTagsObjects] = useTagsObject(selectedTags, allTagsObjects)

  const siteURL = R.pathOr(null, ['site', 'siteMetadata', 'siteUrl'], data)

  const formRedirect = formRedirection(siteURL, countryCode, navLocale, 'lead')

  const pageMetaData = {
    metaTitle: R.pathOr('', ['metaTitle'], metaTitle) || metaTitle,
    metaDescription: R.pathOr('', ['metaDescription'], metaDescription),
    siteMetaData: R.pathOr('', ['siteMetaData'], data),
    genericData: R.pathOr('', ['contentfulGenericData'], data),
  }

  useEffect(() => {
    if (query.tags) {
      setSelectedTags(query.tags.split(',').map(decodeURIComponent))
    }
  }, [query.tags])

  useEffect(() => {
    const handlePopState = () => {
      const newQuery = queryString.parse(window.location.search)

      setSelectedTags(
        newQuery.tags ? newQuery.tags.split(',').map(decodeURIComponent) : [],
      )
    }

    window.addEventListener('popstate', handlePopState)

    return () => {
      window.removeEventListener('popstate', handlePopState)
    }
  }, [])

  const filteredWorkers = useFilteredWorkers(workers, selectedTags)
  const handleTagClick = useTagClick(location, selectedTags, setSelectedTags)

  return (
    <>
      <SEO
        pageContext={pageContext}
        countryData={countryData}
        pageMetaData={pageMetaData}
      />
      <SectionComponent sections={sections} />
      <Section
        id="build-your-team"
        hasPaddingBottom={false}
        hasPaddingTop={false}
      >
        <Modal
          stepByStepSectionContent={stepByStepSectionContent}
          content={modalContent}
          items={modalContentItems}
        />
      </Section>
      <Decorations>
        <Decoration webSiteName="keyrus" color="orange" right={0} top={130} />
        <Decoration webSiteName="keyrus" color="blue" left={0} top={154} />
      </Decorations>
      <Section hasPaddingTop={false}>
        <Grid
          container
          spacing={1}
          style={isMobile ? {marginTop: 0} : {marginTop: 70}}
        >
          <Grid item xs={12} sm={12} md={4} style={{padding: 0}}>
            <FiltersContainer searchPlaceholder={searchPlaceholder} hasBorder>
              <Filter
                currentTags={newLanguagesTagsObjects}
                filterLabel="By languages"
                filterSeeMoreLabel={filterSeeMoreLabel}
                filterSeeLessLabel={filterSeeLessLabel}
                tags={newLanguagesTagsObjects}
                hasObjectsTags
                onTagClick={handleTagClick}
                selectedTags={selectedTags}
                useNewVersion
              />
              <Filter
                currentTags={newDataIntegrationsTagsObjects}
                filterLabel={filterByDataIntegrationLabel}
                filterSeeMoreLabel={filterSeeMoreLabel}
                filterSeeLessLabel={filterSeeLessLabel}
                tags={newDataIntegrationsTagsObjects}
                hasObjectsTags
                onTagClick={handleTagClick}
                selectedTags={selectedTags}
                useNewVersion
              />
              <Filter
                currentTags={newArtificialIntelligencesTagsObjects}
                filterLabel={filterByArtificialIntelligenceLabel}
                filterSeeMoreLabel={filterSeeMoreLabel}
                filterSeeLessLabel={filterSeeLessLabel}
                tags={newArtificialIntelligencesTagsObjects}
                hasObjectsTags
                onTagClick={handleTagClick}
                selectedTags={selectedTags}
                useNewVersion
              />
              <Filter
                currentTags={newProgrammingLanguagesTagsObjects}
                filterLabel={filterByProgrammingLanguageLabel}
                filterSeeMoreLabel={filterSeeMoreLabel}
                filterSeeLessLabel={filterSeeLessLabel}
                tags={newProgrammingLanguagesTagsObjects}
                hasObjectsTags
                onTagClick={handleTagClick}
                selectedTags={selectedTags}
                useNewVersion
              />
              <Filter
                currentTags={newDataVisualizationsTagsObjects}
                filterLabel={filterByDataVisualizationLabel}
                filterSeeMoreLabel={filterSeeMoreLabel}
                filterSeeLessLabel={filterSeeLessLabel}
                tags={newDataVisualizationsTagsObjects}
                hasObjectsTags
                onTagClick={handleTagClick}
                selectedTags={selectedTags}
                useNewVersion
              />
              <Filter
                currentTags={newDataGovernancesTagsObjects}
                filterLabel={filterByDataGovernanceLabel}
                filterSeeMoreLabel={filterSeeMoreLabel}
                filterSeeLessLabel={filterSeeLessLabel}
                tags={newDataGovernancesTagsObjects}
                hasObjectsTags
                onTagClick={handleTagClick}
                selectedTags={selectedTags}
                useNewVersion
              />
              <Filter
                currentTags={newDataPlatformsTagsObjects}
                filterLabel={filterByDataPlatformsLabel}
                filterSeeMoreLabel={filterSeeMoreLabel}
                filterSeeLessLabel={filterSeeLessLabel}
                tags={newDataPlatformsTagsObjects}
                hasObjectsTags
                onTagClick={handleTagClick}
                selectedTags={selectedTags}
                useNewVersion
              />
              <Filter
                currentTags={newSkillsTagsObjects}
                filterLabel={filterByOtherSkillsLabel}
                filterSeeMoreLabel={filterSeeMoreLabel}
                filterSeeLessLabel={filterSeeLessLabel}
                tags={newSkillsTagsObjects}
                hasObjectsTags
                onTagClick={handleTagClick}
                selectedTags={selectedTags}
                useNewVersion
              />
            </FiltersContainer>
          </Grid>
          <Grid item xs={12} sm={12} md={8} style={{padding: 0}}>
            <Workers
              currentTags={selectedTagsObjects}
              filterSeeMoreLabel={filterSeeMoreLabel}
              workers={filteredWorkers}
              instaIcon={instaIcon}
              items={items}
              setItems={setItems}
              biographyLabel={biographyLabel}
              workExperienceLabel={workExperienceLabel}
              talentIdLabel={talentIdLabel}
              skillsLabel={skillsLabel}
              knowMoreLabel={knowMoreLabel}
              callToActionLabel={callToActionLabel}
              setSelectedTags={setSelectedTags}
              onTagClick={handleTagClick}
            />
          </Grid>
        </Grid>
      </Section>
      <CartSideBar
        items={items}
        setItems={setItems}
        formRedirect={formRedirect}
        formData={formData}
        location={location}
      />
    </>
  )
}

Talents.propTypes = {
  data: shape({
    allContentfulTalents: shape({
      edges: arrayOf(
        shape({
          node: shape({
            metaTitle: playbookHeaderPropTypes,
            metaDescription: playbookHeaderPropTypes,
            sections: FiltersContainerPropTypes,
            stepByStepSectionContent: FilterPropTypes,
            modalContent: FilterPropTypes,
            modalContentItems: FilterPropTypes,
            searchPlaceholder: playbookHeaderPropTypes,
            filterByDataIntegrationLabel: playbookHeaderPropTypes,
            filterByArtificialIntelligenceLabel: playbookHeaderPropTypes,
            filterByProgrammingLanguageLabel: playbookHeaderPropTypes,
            filterByDataVisualizationLabel: playbookHeaderPropTypes,
            filterByDataGovernanceLabel: playbookHeaderPropTypes,
            filterByDataPlatformsLabel: playbookHeaderPropTypes,
            filterSeeMoreLabel: playbookHeaderPropTypes,
            filterSeeLessLabel: playbookHeaderPropTypes,
            biographyLabel: playbookHeaderPropTypes,
            workExperienceLabel: playbookHeaderPropTypes,
            talentIdLabel: playbookHeaderPropTypes,
            skillsLabel: playbookHeaderPropTypes,
            knowMoreLabel: playbookHeaderPropTypes,
            callToActionLabel: playbookHeaderPropTypes,
          }),
        }),
      ),
    }),
    contentfulCountry: playbookHeaderPropTypes,
    siteMetaData: FiltersContainerPropTypes,
    contentfulForm: FiltersContainerPropTypes,
  }).isRequired,
  location: locationPropTypes.isRequired,
  pageContext: pageContextPropTypes.isRequired,
}

Talents.defaultProps = {}

export default Talents

export const pageQuery = graphql`
  query templateTalents(
    $entityRegEx: String
    $nodeLocale: String
    $technicalName: String
  ) {
    allContentfulTalents(
      filter: {node_locale: {eq: $nodeLocale}, slug: {regex: $entityRegEx}}
    ) {
      edges {
        node {
          ...talentsFields
        }
      }
    }
    allContentfulMenuItem(
      filter: {name: {eq: "Partners"}, node_locale: {eq: $nodeLocale}}
    ) {
      edges {
        node {
          title
        }
      }
    }
    allContentfulTag(filter: {node_locale: {eq: $nodeLocale}}) {
      edges {
        node {
          name {
            name
          }
          key_play {
            id
          }
        }
      }
    }
    allContentfulIndustry(filter: {node_locale: {eq: $nodeLocale}}) {
      edges {
        node {
          name {
            name
          }
          key_play {
            id
          }
        }
      }
    }
    allContentfulDepartment(filter: {node_locale: {eq: $nodeLocale}}) {
      edges {
        node {
          name {
            name
          }
          key_play {
            id
          }
        }
      }
    }
    allContentfulService(filter: {node_locale: {eq: $nodeLocale}}) {
      edges {
        node {
          ...serviceFields
        }
      }
    }
    allContentfulWorker(filter: {node_locale: {eq: $nodeLocale}}) {
      nodes {
        ...workerFields
      }
    }
    contentfulCountry(
      node_locale: {eq: $nodeLocale}
      technicalName: {eq: $technicalName}
    ) {
      id
      name
      ...countryDataFields
      ...partnerFields
      services {
        topLevelServices {
          entryId
          subLevelServices {
            entryId
          }
        }
      }
    }
    siteMetaData: site {
      ...SiteMetadata
    }
    contentfulGenericData {
      genericKeywords {
        content
      }
    }
    contentfulSocialMediaShare(name: {regex: "/LinkedIn/"}) {
      icon {
        file {
          url
        }
      }
    }
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    contentfulForm(
      name: {regex: $entityRegEx}
      node_locale: {eq: $nodeLocale}
    ) {
      ...FormFields
    }
  }
`
