import {makeStyles} from '@mui/styles'

const useStyles = makeStyles(({spacing, breakpoints, layout, palette}) => ({
  main: {
    minHeight: 1000,
  },
  tagsContainer: {
    '& button': {
      marginRight: spacing(1),
    },
  },
  cardGrid: {
    marginTop: spacing(2),
    marginBottom: spacing(2),
    [breakpoints.up('sm')]: {
      paddingLeft: 0,
      paddingRight: 24,
    },
    [breakpoints.down('sm')]: {
      padding: 0,
      width: '100%',
    },
    [breakpoints.between('sm', 'md')]: {
      paddingRight: 12,
      paddingLeft: 12,
    },
  },
  card: {
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    gap: spacing(1),
    height: 660,
    lineHeight: spacing(4),
    border: `1px solid ${palette.background.contrastGrey}`,
    fontFamily: 'CamptonLight',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: spacing(4),
    padding: spacing(3),
    boxShadow: 'none',
    '& div:nth-child(0)': {
      minHeight: 200,
    },
    '& img': {
      borderRadius: 4,
      objectFit: 'cover',
      height: 100,
      [breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    [breakpoints.down('sm')]: {
      height: 'fit-content',
    },
  },
  workerHeader: {
    minHeight: spacing(8),
    gap: spacing(1),
    display: 'flex',
    borderBottom: `1px solid ${palette.background.contrastGrey}`,
    padding: spacing(1, 0),
    justifyContent: 'space-between',
    '& > div': {
      display: 'flex',
    },
  },
  mainInfos: {
    display: 'flex',
    flexDirection: 'column',
    lineHeight: '24px',

    '& div': {
      [breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    '& div span:nth-child(1)': {
      display: 'flex',
      color: palette.text.grey,
      fontFamily: 'CamptonMedium',
    },
    '& > span:first-child': {
      fontFamily: 'CamptonMedium',
    },
  },
  idInfos: {
    display: 'flex',
    gap: 4,
    '& > span': {
      fontSize: 12,
    },
  },
  workerInfoTop: {
    display: 'flex',
    height: 230,
    flexDirection: 'column',
    borderBottom: `1px solid ${palette.background.contrastGrey}`,
    padding: spacing(1, 0),
    '& > p': {
      margin: '.5rem 0rem',
    },

    [breakpoints.down('sm')]: {
      height: '100%',
    },
  },
  workerInfoBottom: {
    display: 'flex',
    flexDirection: 'column',
    padding: spacing(1, 0),
  },
  cta: {
    display: 'flex',
    flexDirection: 'column',
    padding: spacing(1, 0),
  },
  icon: {
    marginTop: spacing(1),
    width: 24,
    height: 24,
  },
  slideSocials: {
    lineHeight: spacing(4),

    '& > a': {
      opacity: '0.7',

      '&:hover': {
        background: 'transparent',
        opacity: 1,
      },
    },
    '& > a:nth-last-of-type(1)': {
      paddingRight: 0,
    },
    '& img': {
      width: 24,
      height: '100%',
    },
  },
  cardLink: {
    textDecoration: 'none',
  },
  cardMedia: {
    paddingTop: '75%',
    borderRadius: 4,
  },
  cardContent: {
    flexGrow: 1,
    width: '100%',
    padding: spacing(1, 0),
  },
  cardTitle: {
    fontWeight: 500,
    fontSize: 20,
    textAlign: 'left',
    fontFamily: 'CamptonMedium',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
  },
  image: {
    width: 50,
    height: 50,
    background: palette.background.default,
    position: 'relative',
    borderRadius: spacing(10),
    transform: 'translateZ(0)',

    '& div': {
      borderRadius: spacing(10),
      transform: 'translateZ(0)',

      width: '100%',
      height: '100%',
    },
  },
  partnersCard: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 4,
    boxShadow: 'none',
    cursor: 'pointer',
    flexWrap: 'wrap',
  },
  partnerTitle: {
    fontWeight: 500,
    fontSize: 20,
    textAlign: 'center',
    fontFamily: 'CamptonMedium',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
  },
  imageContainer: {
    display: 'flex',
    alignItems: 'center',
    width: 200,
    height: 120,
    position: 'relative',
    justifyContent: 'center',
    margin: 'auto',
    '& img': {
      objectFit: 'cover',
      maxWidth: '70%',
      maxHeight: '70%',
    },
  },
  flex: {
    display: 'flex',
    gap: 10,
  },
  available: {
    color: palette.primary.main,
  },
  notAvailable: {
    color: palette.tertiary.main,
  },
  label: {
    fontFamily: 'CamptonMedium',
  },
}))

export default useStyles
